import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import blogStyles from './blog.module.scss'
import Head from '../components/head'

const blogPost = ({ node }) => {

  return (
    <li className={blogStyles.post}>
      <h2>
        <Link to={`/blog/${node.slug}`}>
          {node.title}
        </Link>
      </h2>
      <p>{node.date}</p>
    </li>
  )
}

const BlogPage = () => {

  const data = useStaticQuery(graphql`
  query {
    allContentfulBlogPost (
      sort: {
        fields:publishedDate,
        order:DESC
      }
    ) {
      edges {
        node {
          title,
          slug,
          publishedDate (formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
    `);
  if (data === undefined) {
    return (

      <Layout>
        <h1>Blog</h1>
        <p>Nothing to show here at the moment.</p>
      </Layout>
    )
  }
  const edges = data.allContentfulBlogPost.edges;

  return (
    <Layout>
      <Head title='Blog'/>
      <h1>Blog</h1>
      <ol className={blogStyles.posts}>
        {edges.map(edge => blogPost(edge))}
      </ol>
    </Layout>
  )
}

export default BlogPage